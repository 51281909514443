import React from 'react'
import Header from'../components/Header'
import Footer from'../components/Footer'
import { Link } from 'react-router-dom'
import LazyLoad from 'react-lazyload'
const ParentPortal = () => {
  return (
     <>
       <Header/>
        <div className="innerslide"></div> 
       
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                    <ul className=" breadcrumbli">
                            <li><Link to="/">Home</Link></li> 
                            <li>Parent  Guidelines</li> 
                        </ul>
                        <h1>  Parent  Guidelines </h1>
                    </div>
                </div>
           
                <div className="row">
                <div className="col-12">
                 
                <div className="erp_sec">
                <h3 className="Subhead"><b>CampusCare10x Application Guidelines & Instructions </b></h3>
                <h4 className="guideheading">Fees payment process</h4>
            <p className="details">
                1. Login to the portal <Link to="#" className="parent-link" target="_blank">#</Link> using the login credentails
            </p>
            <p className="details">
                2. Click on Pay Online button on left menu bar
            </p>
            <p className="details">
                3. Select the Installment and click on Proceed to Pay button
            </p>
            <p className="details">
                4. Click on Pay now button and select your convenient mode of payment (Debit/Credit cards, Internet Banking, UPI/QR and Wallet).
            </p>
            <p className="details">
                5. After successful payment, you will be redirected back to parent portal where you can download the free receipts of payment made from Fee section.
            </p>
            <div className="clr10"></div>
            
                    <h4 className="guideheading">Mobile App</h4>
                <p className="details">
                    1. Via Mobile App
                </p>
                <p className="details">
                    2. Download App
                </p>
                <p className="details">iOS: CampusCare10X on App Store: - <Link to="https://apps.apple.com/in/app/campuscare-10x/id1611283814" className="parent-link" target="_blank">
                    <LazyLoad><img src="https://webapi.entab.info/api/image/SMSUM/public/Images/app1.png"  className="img-fluid"/></LazyLoad>
                    </Link> <br/> Android: CampusCare10X on Google Play-  <Link to="https://play.google.com/store/apps/details?id=com.entab.learninglab" className="parent-link" target="_blank">
                        <LazyLoad><img src="https://webapi.entab.info/api/image/SMSUM/public/Images/app2.png" className="img-fluid"/></LazyLoad>
                        </Link> </p>
                    
                <h4 className="guideheading">Payment Steps: </h4>
            <p className="details">
                1. Install and open the CampusCare10X app. Enter the school code: <Link to="#"># </Link> .
            </p>
            <p className="details">
                2. Log in successfully, then tap on the Fees icon.
            </p>
            <p className="details">3. Choose the installment and press Proceed to Pay.</p>
            <p className="details">4. Select Pay now and your payment method.</p>
            <p className="details">5. Post-payment, the app redirects you for receipt download.</p>
            <div className="clr10"></div> 
                    <div className="assistance">
                    <h4 className="guideheading">Please call or write for assistance:</h4>
                            <p className="details">
                                <strong> <Link to="https://www.entab.in/contact-us.html">PARENT HELP DESK</Link></strong>
                                ( 8 AM to 8:00 PM ) - <strong>Tele No.</strong> 011- 43193333-Ext : 5,<strong> E-Mail :</strong>
                                <strong><Link to="mailto:parentdesk@entab.in" className="parent-link"> parentdesk@entab.in</Link></strong>
                                </p>
                    </div>
            </div>
                </div>
            </div> 
        </div> 
        </div> 
   <Footer/>
  </>
  )
}

export default ParentPortal
