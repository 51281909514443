import {getEvents} from'../Service/Api' 
import Slider from "react-slick";
import { useEffect, useState } from 'react'; 
import LazyLoad from 'react-lazyload';
const Events = () => {
const [data,setData] = useState([]);
const [loading,setLoading] = useState(true);
const [error,setError] = useState(null);

 useEffect(() => {
     const fetchData = async () =>{
          try{
            const result = await getEvents();
            setData(result);

          } catch {
            setError(error)
          }finally{
            setLoading(false)
          }

     }
fetchData();
 },[]);

 const empty = [
     { title:"Stay tunned for more Updates",date:"DD.MM.YY",venue:"school Campus",time:"00:00"},
     { title:"Stay tunned for more Updates",date:"DD.MM.YY",venue:"school Campus",time:"00:00"},
     { title:"Stay tunned for more Updates",date:"DD.MM.YY",venue:"school Campus",time:"00:00"},
 ]
    var settings = {
        autoplay: true,
        infinite: true,
        autoplaySpeed: 2000,
        speed: 1000,
        slidesToShow:3,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows: false, 
        adaptiveHeight: true,
        responsive: [
            {
              breakpoint: 1400,
              settings: {
                slidesToShow: 2,
                slidesToScroll:1,
              }
            },
            {
                breakpoint: 1070,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll:1,
                }
              },
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 2,
                slidesToScroll:1,
              }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow:2,
                slidesToScroll: 1,
                initialSlide: 1
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
      };
   

    return (
        <Slider className='owl-theme' loop margin={10} {...settings}>
         
        {data && data.length > 0 ?  (data.map((item,index) => (
        <div className="item" key={index}>
            <div className='evt-blk'>
                <div className="evt-img">
                    <LazyLoad><img src={`https://webapi.entab.info/api/image/${item.images}`} className="img-fluid" /></LazyLoad>
                    
                    <div className="evt-title"><h3>{item.title}</h3></div> 
                </div>
                <div className='evt-content'> 
                    <p>
                      <LazyLoad><img src="https://webapi.entab.info/api/image/SMSUM/public/Images/calender.png" /></LazyLoad>{item.date}<span>
                      <LazyLoad><img src="https://webapi.entab.info/api/image/SMSUM/public/Images/clock.png"></img></LazyLoad>{item.time}</span></p>
                    <p><LazyLoad><img src="https://webapi.entab.info/api/image/SMSUM/public/Images/location.png" /></LazyLoad>{item.venue}</p>
                </div>
            </div>
    </div> 
     ))) :  (empty.map((item,index) => (
        <div className="item" key={item.index}>
            <div className='evt-blk'>
                <div className="evt-img">
                    <LazyLoad><img src="https://webapi.entab.info/api/image/SMSUM/public/Images/events.jpg" className="img-fluid" /></LazyLoad>
                    
                    <div className="evt-title"><h3>{item.title}</h3></div> 
                </div>
                <div className='evt-content'> 
                    <p>
                      <LazyLoad><img src="https://webapi.entab.info/api/image/SMSUM/public/Images/calender.png" /></LazyLoad>{item.date}<span>
                      <LazyLoad><img src="https://webapi.entab.info/api/image/SMSUM/public/Images/clock.png"></img></LazyLoad>{item.time}</span></p>
                    <p><LazyLoad><img src="https://webapi.entab.info/api/image/SMSUM/public/Images/location.png" /></LazyLoad>{item.venue}</p>
                </div>
            </div>
    </div> 
     )))}


          { /*  <div className="item">
                <div className='evt-blk'>
                    <div className="evt-img">
                        <img src="https://webapi.entab.info/api/image/SMSUM/public/Images/event1.png" />
                        
                        <div className="evt-title"><h3>Gautam Buddha Jayanti</h3></div> 
                    </div>
                    <div className='evt-content'> 
                        <p><img src="https://webapi.entab.info/api/image/SMSUM/public/Images/calender.png" />23 May, 2024 <span><img src="https://webapi.entab.info/api/image/SMSUM/public/Images/clock.png"></img>08:00AM</span></p>
                        <p><img src="https://webapi.entab.info/api/image/SMSUM/public/Images/location.png" />School Campus </p>
                    </div>
                </div>
            </div>
            <div className="item">
                <div className='evt-blk'>
                    <div className="evt-img">
                        <img src="https://webapi.entab.info/api/image/SMSUM/public/Images/event2.png" />
                        
                        <div className="evt-title"><h3>International workers day</h3></div> 
                    </div>
                    <div className='evt-content'> 
                        <p><img src="https://webapi.entab.info/api/image/SMSUM/public/Images/calender.png" />12 May, 2024 <span><img src="https://webapi.entab.info/api/image/SMSUM/public/Images/clock.png"></img>08:00AM</span></p>
                        <p><img src="https://webapi.entab.info/api/image/SMSUM/public/Images/location.png" />School Campus </p>
                    </div>
                </div>
            </div>
            <div className="item">
                <div className='evt-blk'>
                    <div className="evt-img">
                        <img src="https://webapi.entab.info/api/image/SMSUM/public/Images/event3.png" />
                        
                        <div className="evt-title"><h3>ravindra nath tagore jayanti</h3></div> 
                    </div>
                    <div className='evt-content'> 
                        <p><img src="https://webapi.entab.info/api/image/SMSUM/public/Images/calender.png" />08 May, 2024 <span><img src="https://webapi.entab.info/api/image/SMSUM/public/Images/clock.png"></img>08:00AM</span></p>
                        <p><img src="https://webapi.entab.info/api/image/SMSUM/public/Images/location.png" />School Campus </p>
                    </div>
                </div>
    </div> */ }
        
            
        </Slider>
    );
};

export default Events;
