// import $ from 'jquery'
import Header from '../components/Header'
import News from '../components/News'
import Copyright from '../components/Copyright'
import SliderComponent from '../components/SliderComponent'
import Events from '../components/Events'
import PrincipalMessage from '../components/PrincipalMessage'
import Birthday from '../components/Birthday' 
import Footer from '../components/Footer'
import Notification from '../components/Notification'
import { Link, Navigate } from 'react-router-dom' 
import Highlights from '../components/Highlights' 
import Facilities from '../components/Facilities';
import Gallery from '../components/Gallery'
import OnlineReg from'../components/OnlineReg'
import Campuscare from'../components/Campuscare'
import LazyLoad from 'react-lazyload'

//import WOW from 'wow.js'; // Import WOW.js library
const Home = () => {
 
    return (
        <> 
        <OnlineReg/> 
        <Notification />  
         <Campuscare/>
          
            <Header/>  
            <div className='container-fluid min-ht'>
                <div className="row">

                    <div className='col-md-12 pd-0 order-lg-12'>
                        <SliderComponent />
                    </div>
                </div>

            </div>
            <div class="bg-image">
            <div className='vision-mission'>
                <div class="container">
                    <div className='row'>
                        <div className='col-lg-3 col-md-6' data-animation="fadeInLeft">
                            <div className='mission'>
                                <h3><LazyLoad> <img src='https://webapi.entab.info/api/image/SMSUM/public/Images/mission.gif'></img></LazyLoad> Mission</h3>
                                <p>To impart a value-based education by:</p>
                                <ul>
                                    <li>Love and worship of God through daily prayer</li>
                                    <li>Adherence to truth</li>
                                    <li>Concern for and service to the poor.</li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 vision-mission-col animateMe' data-animation="fadeInRight">
                            <div className='vission'>
                                <h3><LazyLoad> <img src='https://webapi.entab.info/api/image/SMSUM/public/Images/vision.gif'></img></LazyLoad> Vision</h3>
                                <p>Inspired by the word of God, we the FSMA educators endeavour to <span>EMPOWER & TRANSFORM </span>young minds, emphasizing holistic development for a just & human society</p>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 vision-mission-col animateMe' data-animation="fadeInRight">
                            <div className='motto'>
                                <h3><LazyLoad> <img src='https://webapi.entab.info/api/image/SMSUM/public/Images/motto.gif'></img></LazyLoad> MOTTO</h3>
                                <p> <center>“LET YOUR LIGHT SHINE”</center></p>
                                <p>This Motto invites the students to spread effectively all around them the <span>INSPIRATION ,KNOWLEDGE & EXCELLENCE OF CHARACTER & PERSONALITY ACQUIRED</span> in the school</p>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 vision-mission-col animateMe' data-animation="fadeInRight">
                            <div className='highlight'>

                                <h3><LazyLoad> <img src='https://webapi.entab.info/api/image/SMSUM/public/Images/announcement.gif'></img></LazyLoad> Announcement</h3>
                                <Highlights />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="about-us">
                <div className='container'>
                    <div className='row'>
                        
                        <div className='col-lg-6 col-md-12 animateMe' data-animation="fadeInLeft">
                            <LazyLoad><img src="https://webapi.entab.info/api/image/SMSUM/public/Images/about-img.png" className='img-fluid' /></LazyLoad>
                        </div>
                        <div className='col-lg-6 col-md-12 animateMe' data-animation="fadeInRight">
                         <div className="title">
                                <div>
                                    <p>history</p>
                                    <h3>about us</h3>
                                </div>
                                <LazyLoad><img src="https://webapi.entab.info/api/image/SMSUM/public/Images/logo-icon.png" /></LazyLoad>
                            </div>
                            <p><span>St. Mary’s Convent Senior Secondary School Ujjain is run by the Franciscan Sisters of St. Mary of the Angels (F.S.M.A)</span> a religious Congregation dates back to 8th July 1964. Three young enthusiastic and zealous pioneers arrived at Ujjain to open an English Medium School in response to the demand of the local population and Mr. Tickoo, the then Collector of Ujjain. The School started officially functioning in August 1964 in a rented building at Freeganj with about 150 students on the roll. In the year 1966, the school was shifted to its present building.</p>
                            <p>This temple of learning was built brick by brick and its traditions year by year. The tiny seed, which was sown in 1964, has grown into a mighty tree now. In the span of forty seven years, thousands of students have joyously spent their best years here. Today it has over 2000 students.</p>
                            <div className='button abt-button'><Link to="/History">
                                <LazyLoad><img src="https://webapi.entab.info/api/image/SMSUM/public/Images/Read-more.png" /></LazyLoad>
                                </Link></div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <div className='news-event'>
                <div>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-4 news-bg animateMe' data-animation="fadeInLeft">
                            <div className="title">
                                <div>
                                    <p>whats happening</p>
                                    <h3>latest news &<br></br> Notices</h3>
                                </div>
                                <div className='button'><Link to="/News">
                                    <LazyLoad><img src="https://webapi.entab.info/api/image/SMSUM/public/Images/more-icon.png" className='ml-0'></img></LazyLoad>
                                    </Link></div>
                            </div>
                            <div className='news'>
                                
                                <div className='news-blk'>
                                    <News /> 
                                    <LazyLoad><img src='https://webapi.entab.info/api/image/SMSUM/public/Images/news-img.png' /></LazyLoad>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-8 animateMe' data-animation="fadeInRight">
                        <div className="title">
                                <div>
                                    <p>Events & Updates</p>
                                    <h3>Upcoming<br></br> Events</h3>
                                </div>
                                <div className='button'><Link to="/Events">
                                    <LazyLoad><img src="https://webapi.entab.info/api/image/SMSUM/public/Images/more-icon.png" className='ml-0'></img></LazyLoad>
                                    </Link></div>
                            </div>
                            <div className='events'>
                                 
                                <div className='events-blk'>
                                    <Events /> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <div className='facilities-main'>
            <div className="facilities">
                <div className="title center">
                    <div>
                        <p>facilities in the school</p>
                        <h3>Our Facilities</h3>
                    </div> 
                </div>
                <Facilities />
            </div>
            </div>
          
         
         
            <div className='birth-topper'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 animateMe" data-animation="fadeInLeft">
                            <div className='birthday'>
                            <div className="title center">
                    <div>
                        <p>Stars of the day</p>
                        <h3>Birthday section</h3>
                    </div> 
                </div>
                               
                            </div>
                        </div>
                        <Birthday />
                        {/* <div className="col-lg-8 col-md-8 animateMe" data-animation="fadeInRight">
                            <div className='topper'>
                                <div className="title">
                                    <div class="title-gif">
                                        <img src='https://webapi.entab.info/api/image/SMSUM/public/Images/trophy.gif'></img>
                                    </div>
                                    <h3>SCHOOL TOPPERS</h3>
                                </div>
                                <Topper />
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className="message">
                <div className='container'>
                    <div class="row">
                        <div class="col-md-12">
                        <div className="title center">
                    <div>
                        <p>from the desk of</p>
                        <h3>Messege section</h3>
                    </div> 
                </div>
                             
                                <PrincipalMessage />
                            
                        </div>
                    </div>
                </div>
            </div>
            <div className='gallery'>
                
                 <Gallery /> 
             
            </div>
            
            <Footer />
            <Copyright />
        </>
    )
}
export default Home;