import React from 'react'
import Header from'../components/Header'
import Footer from'../components/Footer'
import { Link } from 'react-router-dom'
import LazyLoad from 'react-lazyload'
const ContactUs = () => {
  return (
     <>
       <Header/>
        <div className="innerslide"></div> 
       
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                    <ul className=" breadcrumbli">
                            <li><Link to="/">Home</Link></li>  
                            <li>ContactUs </li>
                        </ul>
                        <h1>  ContactUs </h1>
                    </div>
                </div>
      <div className="contactusbox">
           <div className="contactusdiv fstcontact">
                 <div className="contactusimg">
                        <LazyLoad><img src="https://webapi.entab.info/api/image/SMSUM/public/Images/Location-icon.png" className="img-fluid" alt="" /></LazyLoad>
                        <h4>Location</h4>
                 </div>
                 <div className="contactusdesc">
                        <div className="contactusicn"><i className="bi bi-geo-alt-fill"></i></div>
                        <Link to="https://maps.app.goo.gl/iDjDPrS9qvEbxkRj8" target="_blank"> Dewas Rd, Azad Nagar, Rishi Nagar, Ujjain, Madhya Pradesh 456010</Link>
                 </div>
           </div>
           <div className="contactusdiv scdcontact">
                <div className="contactusimg">
                        <LazyLoad><img src="https://webapi.entab.info/api/image/SMSUM/public/Images/Email-icon.png" className="img-fluid" alt="" /></LazyLoad>
                        <h4>Email</h4>
                 </div>
                 <div className="contactusdesc">
                   <div className="contactusicn">
                       <i className="bi bi-envelope-fill"></i>
                   </div>
                    <div> 
                    <p> <Link to="/mailto:stmarysujn@rediffmail.com">Principal : stmarysujn@rediffmail.com</Link></p>
                    <p> <Link to="/mailto:stmarysujn@rediffmail.com">Office : stmarysujn@rediffmail.com</Link> </p>
                    <p> <Link to="/mailto:opinionsstmarysujn@gmail.com">Enquiry/Opinions : opinionsstmarysujn@gmail.com</Link></p>
                    </div>   
                 </div>
            </div> 
           <div className="contactusdiv thrdcontact">
                 <div className="contactusimg">
                       <LazyLoad><img src="https://webapi.entab.info/api/image/SMSUM/public/Images/Phone-icon.png" className="img-fluid" alt="" /></LazyLoad>
                        <h4>Phone</h4>
                 </div>
                 <div className="contactusdesc"> 
                           <div className="contactusicn">
                            <i className="bi bi-telephone-fill"></i>
                          </div>
                    <div>
                        <p> <Link to="/tel:0734-2512771">Secondary Section : 0734-2512771</Link> </p>
                        <p>  <Link to="/tel:0734-2514771">Primary Section : 0734-2514771</Link></p>
                    </div> 
                 </div>
           </div>
      </div>  
      <div className="contactiframe">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3668.0425149590656!2d75.79190758688465!3d23.168648356913124!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39637441f779fedd%3A0xefa1d8f12baca492!2sSt.Mary%E2%80%99s%20Convent%20Senior%20Sec.%20School%2C!5e0!3m2!1sen!2sin!4v1718167494216!5m2!1sen!2sin" width="100%" height="400" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
</div>
</div>
<Footer/>
    </>
  )
}

export default ContactUs
